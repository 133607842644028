$(document).ready(function() {

  $('.m-menu').click(function() {
    if ($('body').hasClass('m-menu-open')) {
      $('body').removeClass('m-menu-open');
    } else {
      $('body').addClass('m-menu-open');
      $("html, body").animate({ scrollTop: "0" });
    }
  });

  $('.has-children .button').on('touchstart', function() {
    $(this).parent().find('ul').slideToggle('fast');
  })

  // opening hours
  if ($('body').hasClass('home')) {
    //calcTimeRemaining();
    //setInterval(calcTimeRemaining,15000);

    var scrolled = 0;
    window.addEventListener('scroll', handleScroll);

    addNewsTicker();
  }


  function handleScroll() {
    scrolled = window.scrollY;
    var scrollOffset = 100;
    if (scrolled > scrollOffset) {
      document.querySelector('body').classList.add('scrolling');
    } else {
      if (!$('body').hasClass('m-menu-open')) {
        document.querySelector('body').classList.remove('scrolling');
      }
    }
  }


  function calcTimeRemaining() {
    var now = moment();
    var end = moment('17:00', 'HH:mm');
    var duration = moment.duration(end.diff(now));
    var str = '';
    if (duration.hours() < 0 || duration.minutes() < 0) {
      var t = 15 + duration.hours();
      var m = 60 + duration.minutes();
      str = 'It is now <span>' + now.format('HH:mm') + '</span>. We\'ll open in <span>' + t + 'h' + m + 'm</span>';
    } else {
      str = 'It is now <span>' + now.format('HH:mm') + '</span>. We\'ll close in <span>' + duration.hours() + 'h' + duration.minutes() + 'm</span>';
    }

    $('.opening').html(str);
  }

  function addNewsTicker() {
    $('.messages').marquee({
      speed: 45,
      gap: 0,
      delayBeforeStart: 0,
      direction: 'left',
      duplicated: true,
    });
  }

  // $('body').on('mouseenter','.item.in',
  $('body').on('mouseenter', '.item.in', function() {
    // console.log('n')
    anime({
      targets: this.querySelectorAll('.svg-in'),
      d: [
        { value: 'M2,0 L508,0 L508,297 L2,297 L2,0 Z M12.2916618,258.971741 C11.1086123,298.59319 40.2103307,287.86272 40.2103307,287.86272 C225.581842,238.795205 460.09216,286.444997 460.09216,286.444997 C498.814281,298.150965 497.215214,265.172652 497.215214,265.172652 L497.215214,36.3697565 C497.215214,-1.29094322 460.09216,16.3720615 460.09216,16.3720615 C236.749309,78.4429557 35.7413936,13.1269071 35.7413936,13.1269071 C9.76305597,6.23989606 12.2916618,39.471838 12.2916618,39.471838 L12.2916618,132.72613 L12.2916618,258.971741 Z' },
        { value: 'M2,-2.84217094e-14 L508,-2.84217094e-14 L508,297 L2,297 L2,-2.84217094e-14 Z M12,290 C47.515625,290 238,290 238,290 C438.918945,290 497,290 497,290 C497.215214,243.570312 497.215214,148 497.215214,148 L497.215214,36.3697565 C497.215214,-1.29094322 497,12 497,12 C235.354492,12 238,12 238,12 C210.975586,12 54.9550781,12 12,12 L12,148.5 L12,290 Z' }
      ],
      easing: 'easeInOutQuad',
      duration: 150,
      loop: false
    });
  });
  $('body').on('mouseleave', '.item.in', function() {
    anime({
      targets: this.querySelectorAll('.svg-in'),
      d: [
        { value: 'M2,-2.84217094e-14 L508,-2.84217094e-14 L508,297 L2,297 L2,-2.84217094e-14 Z M12,290 C47.515625,290 238,290 238,290 C438.918945,290 497,290 497,290 C497.215214,243.570312 497.215214,148 497.215214,148 L497.215214,36.3697565 C497.215214,-1.29094322 497,12 497,12 C235.354492,12 238,12 238,12 C210.975586,12 54.9550781,12 12,12 L12,148.5 L12,290 Z' },
        { value: 'M2,0 L508,0 L508,297 L2,297 L2,0 Z M12.2916618,258.971741 C11.1086123,298.59319 40.2103307,287.86272 40.2103307,287.86272 C225.581842,238.795205 460.09216,286.444997 460.09216,286.444997 C498.814281,298.150965 497.215214,265.172652 497.215214,265.172652 L497.215214,36.3697565 C497.215214,-1.29094322 460.09216,16.3720615 460.09216,16.3720615 C236.749309,78.4429557 35.7413936,13.1269071 35.7413936,13.1269071 C9.76305597,6.23989606 12.2916618,39.471838 12.2916618,39.471838 L12.2916618,132.72613 L12.2916618,258.971741 Z' },
      ],
      easing: 'easeInOutQuad',
      duration: 150,
      loop: false
    });
  })


  $('body').on('mouseenter', '.item.out:not(".static")', function() {
    anime({
      targets: this.querySelectorAll('.svg-out'),
      d: [
        { value: 'M0,0 L172,0 L172,101 L0,101 L0,0 Z M11.7638949,11.2772566 C11.7638949,11.2772566 3.00026564,13.5048143 3.00026564,20.5174551 L3.00026564,79.914922 C3.00026564,79.914922 2.82116633,87.8341398 11.7638949,89.6406281 C11.7638949,89.6406281 37.0992156,96 83.9553757,96 C130.810423,96 160.236105,89.6406281 160.236105,89.6406281 C160.236105,89.6406281 168.999734,87.8341398 168.999734,81.3081177 L168.999734,21.237384 C168.999734,21.237384 169.178834,12.5971262 160.236105,11.2772566 C160.236105,11.2772566 145.373088,6 86.0355974,6 C26.698107,6 11.7638949,11.2772566 11.7638949,11.2772566 Z' },
        { value: 'M0,0 L172,0 L172,101 L0,101 L0,0 Z M3,6 C3,6 3.00026564,13.5048143 3.00026564,20.5174551 L3.00026564,79.914922 C3.00026564,79.914922 3.00026564,84.8807579 3,96 C3,96 37.0992156,96 83.9553757,96 C130.810423,96 168.999936,96 168.999936,96 C168.999936,96 168.999734,87.8341398 168.999734,81.3081177 L168.999734,21.237384 C168.999734,21.237384 168.999734,15.2761011 168.999936,6 C168.999936,6 145.373088,6 86.0355974,6 C26.698107,6 3,6 3,6 Z' }
      ],
      easing: 'easeInOutQuad',
      duration: 150,
      loop: false
    });
  });
  $('body').on('mouseleave', '.item.out:not(".static")', function() {
    anime({
      targets: this.querySelectorAll('.svg-out'),
      d: [
        { value: 'M0,0 L172,0 L172,101 L0,101 L0,0 Z M3,6 C3,6 3.00026564,13.5048143 3.00026564,20.5174551 L3.00026564,79.914922 C3.00026564,79.914922 3.00026564,84.8807579 3,96 C3,96 37.0992156,96 83.9553757,96 C130.810423,96 168.999936,96 168.999936,96 C168.999936,96 168.999734,87.8341398 168.999734,81.3081177 L168.999734,21.237384 C168.999734,21.237384 168.999734,15.2761011 168.999936,6 C168.999936,6 145.373088,6 86.0355974,6 C26.698107,6 3,6 3,6 Z' },
        { value: 'M0,0 L172,0 L172,101 L0,101 L0,0 Z M11.7638949,11.2772566 C11.7638949,11.2772566 3.00026564,13.5048143 3.00026564,20.5174551 L3.00026564,79.914922 C3.00026564,79.914922 2.82116633,87.8341398 11.7638949,89.6406281 C11.7638949,89.6406281 37.0992156,96 83.9553757,96 C130.810423,96 160.236105,89.6406281 160.236105,89.6406281 C160.236105,89.6406281 168.999734,87.8341398 168.999734,81.3081177 L168.999734,21.237384 C168.999734,21.237384 169.178834,12.5971262 160.236105,11.2772566 C160.236105,11.2772566 145.373088,6 86.0355974,6 C26.698107,6 11.7638949,11.2772566 11.7638949,11.2772566 Z' },
      ],
      easing: 'easeInOutQuad',
      duration: 150,
      loop: false
    });
  })


  // programme filters
  if (document.getElementById('programme')) {
    var app = new Vue({
      el: '#programme',

      data: {
        filters: ['now'],
        nrOfEvents: 0,
      },

      mounted: function() {
        var self = this;
        $('.filter').click(function() {
          $('.filter').removeClass('active');
          $(this).addClass('active');
          var catid = $('.filter.active').attr('data-catid');
          self.nrOfEvents = 0;
          self.filter(catid);
        });
      },


      methods: {

        filter: function(filter) {
          var self = this;
          self.filters.splice(0, 1);
          self.filters.push(filter);
        },

        youaremytype: function(mytype, startDate, endDate) {
          //return true;
          var self = this;

          if (self.filters[0] == 'all') {
            self.nrOfEvents = 1;
            return true;
          } else if (self.filters[0] == 'now' || self.filters[0] == 'future' || self.filters[0] == 'past') {
            if (endDate == '') {
              endDate = startDate
            }
            // console.log(startDate,moment().format('DD.MM.YY'))
            moment.defaultFormat = 'DD.MM.YY';
            switch (self.filters[0]) {
              case 'now':
                var start = moment(startDate, moment.defaultFormat);
                var end = moment(endDate, moment.defaultFormat);
                if (moment(start).isSameOrBefore() && moment(end).isSameOrAfter()) {
                  self.nrOfEvents = 1;
                  return true;
                } else {
                  return false;
                }
                break;
              case 'future':
                var start = moment(startDate, moment.defaultFormat);
                if (moment(start).isAfter()) {
                  self.nrOfEvents = 1;
                  return true;
                } else {
                  return false;
                }
                break;
              case 'past':
                var end = moment(endDate, moment.defaultFormat);
                if (moment(end).isBefore()) {
                  self.nrOfEvents = 1;
                  return true;
                } else {
                  return false;
                }
                break;
              default:
                // code block
            }
          } else {
            if (self.filters.length > 0) {
              var io = self.filters.indexOf(mytype);
              if (io != -1) {
                self.nrOfEvents = 1;
                return true;
              }
              return false;
            } else {
              self.nrOfEvents = 1;
              return true;
            }
          }

        }
      }

    })
  }

  // build the alphabet
  if ($('.alphabet').length) {
    var res = '';
    var className = 'letter';
    var current = $('.alphabet').attr('data-curr');
    console.log('current:' + current);
    for (var i = 65; i <= 90; i++) {
      var char = String.fromCharCode(i);
      if (char.toUpperCase() == current.toUpperCase() || (current == '' && char == 'A')) {
        className += ' active';
      } else {
        className = 'letter';
      }
      $('.alphabet').append('<button class="' + className + '" data-char="' + char + '">' + char + '</button>');
    }
    if (current.toLowerCase() == 'non-alphabet') {
      className = 'letter number active';
    } else {
      className = 'letter number'
    }
    $('.alphabet').append('<button class="' + className + '" data-char="non-alphabet">123</button>');
    $('.letter').on('click', filterOn);
  }

  function filterOn(event) {
    var char = event.currentTarget.getAttribute('data-char').toUpperCase();
    var lang = $('#container').data('lang');
    var sup = '';
    if (lang == 'nl' || lang == 'fr') {
      sup = '/' + lang;
    }
    //console.log('filtering on ' + char);
    var typeOfItems = $('.alphabet').attr('data-type');
    if (typeOfItems == 'works') {
      window.location = sup + '/works/collection/' + char;
    } else if (typeOfItems == 'distribution') {
      window.location = sup + '/distribution/works/' + char;
    } else {
      window.location = sup + '/artists/' + char;
    }

  }

  $('.alphabet-filter').click(function(){
    $(this).toggleClass('filter-active');
    $('.alphabet').toggleClass('filter-active');
  })

  // fitvids
  $('iframe[src*="vimeo"]').parent().fitVids();

});